import React from "react"
import { Grid, Typography, Container } from "@mui/material"

import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import ScholarshipsHero from "./ScholarshipsHero"
import { Segment } from "../Segment"

const Scholarships = () => {
  return (
    <>
      <ScholarshipsHero />
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Center>
                  <StaticImage
                    src="../../images/logo-2018.svg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={550}
                  />
                </Center>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  “A man should share the action of his time at the peril of
                  being judged not to have lived”
                </Typography>
                <Typography variant="h5" color="secondary" align="center">
                  Oliver Wendell Holmes
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  “In today’s world of ceaseless, oft painful and disturbing
                  change. The conviction is growing that the scholar must become
                  the voice and conscience of the people, or he must someday
                  wake, never to view again the ‘green stillness of the
                  country.’”
                </Typography>
                <FancyHr alt />
                <Typography variant="h5" color="common.white" align="center">
                  Archon Percy L. Julian<br></br>
                  “Faultless Prophets”<br></br>
                  1964 memorial service speech<br></br>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Center>
                  <StaticImage
                    src="../../images/logo-2018.svg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={550}
                  />
                </Center>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  Truth - Courage - Moral Character - Commitment to Higher
                  Education
                </Typography>
                <FancyHr />
                <Typography align="center">
                  Search and enjoy the ELB Foundation Website.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default Scholarships
